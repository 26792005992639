import { Button } from "components/shared/panda/Button"
import { getHref, parseBackground } from "lib/helpers"
import { FullWidthBanner } from "schemas"
import { Container, Stack, styled, VStack } from "styled-system/jsx"

type Props = FullWidthBanner

function Columns({
  title,
  body,
  cta,
  altStyle,

  backgroundColor1,
  backgroundColor2,
  backgroundStyle,
}: Props) {
  return (
    <styled.div
      style={{
        background: parseBackground(
          backgroundColor1,
          backgroundColor2,
          backgroundStyle
        ),
      }}
      css={{
        py: altStyle ? 20 : "24px",
        bgImage: altStyle
          ? null
          : "url(/textures/fullWidthBanner-bg.svg), linear-gradient(91deg, #0000CF 0%, #060086 100%)",
        backgroundPosition: "center 45%",
        backgroundRepeat: "no-repeat",

        lg: {
          py: altStyle ? 20 : "40px",
        },
      }}>
      <Container>
        <Stack
          direction={{ base: "column", lg: "row" }}
          gap="16px"
          align={{ base: "start", lg: "center" }}
          justify="space-between">
          <VStack gap="8px" alignItems="start">
            <styled.h3
              css={{
                color: altStyle ? "#373737" : "white",
                fontFamily: "heading",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",

                lg: {
                  fontSize: "23px",
                  lineHeight: "32px",
                },
              }}>
              {title}
            </styled.h3>
            <styled.p
              css={{
                color: altStyle ? "#373737" : "other.3",
                fontSize: "11px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",

                lg: {
                  fontSize: "16px",
                  lineHeight: "133%",
                },
              }}>
              {body}
            </styled.p>
          </VStack>

          {cta && (
            <Button
              href={getHref(cta)}
              variant="filled"
              color={altStyle ? "white" : null}
              fillColor={altStyle ? "secondary" : "tertiary"}
              size="medium"
              responsive>
              {cta.text}
            </Button>
          )}
        </Stack>
      </Container>
    </styled.div>
  )
}

export default Columns
export type { Props as ColumnsProps }
